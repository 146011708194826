import { AnimationClassNames } from '@fluentui/react';
import { getRTLFlipOptOut } from '@m365-admin/utilities';

import { navCollapsedWidth, navItemHeight } from '../nav/nav.style';
import type {
  INavLinkGroupStyleProps,
  INavLinkGroupStyles,
} from './nav-link-group.types';

// this is used to ensure that the left shadow of the nested nav can be hidden while
// the rest of the shadow can be shown
const shadowOffset = 24;

// width of the flyout navigation
export const flyoutNavWidth = 230;

export const getNavLinkGroupStyles = (
  props: INavLinkGroupStyleProps,
): Partial<INavLinkGroupStyles> => {
  const { isNavCollapsed, isExpanded, isKeyboardExpanded, theme, isCollapsibleSection } =
    props;

  return {
    root: [
      isNavCollapsed &&
        !isCollapsibleSection && {
          selectors: {
            '& *:hover + [class|=nestedNav]': {
              display: 'flex',
            },
          },
        },
    ],
    nestedNav: [
      isNavCollapsed &&
        !isCollapsibleSection && {
          width: `${flyoutNavWidth + navCollapsedWidth}px`,
          position: 'absolute',
          flexDirection: 'column',
          alignItems: 'flex-end',
          display: isKeyboardExpanded ? 'flex' : 'none',
          pointerEvents: 'none',
          selectors: {
            ':hover': {
              display: 'flex',
            },
          },
        },
    ],
    nestedNavHeaderItem: {
      zIndex: 1,
      backgroundColor: theme.palette.neutralTertiaryAlt,
      cursor: 'default',
      selectors: {
        ':hover': {
          backgroundColor: 'inheret',
        },
        ':active': {
          backgroundColor: 'inheret',
        },
        ':focus': {
          backgroundColor: 'inheret',
        },
      },
    },
    nestedNavHeaderItemText: {
      margin: '0px 12px',
    },
    nestedNavLinksWrapper: [
      !isNavCollapsed &&
        isExpanded && [AnimationClassNames.fadeIn400, AnimationClassNames.slideDownIn20],
      ((!isNavCollapsed && !isExpanded) || (isCollapsibleSection && !isExpanded)) && {
        display: 'none',
      },
      isNavCollapsed &&
        !isCollapsibleSection && {
          overflow: 'hidden',
          padding: `${
            shadowOffset + navItemHeight
          }px ${shadowOffset}px ${shadowOffset}px 0px`,
          margin: `-${
            shadowOffset + navItemHeight
          }px -${shadowOffset}px -${shadowOffset}px 0px`,
          selectors: {
            '*[dir="rtl"] &': {
              paddingTop: `${shadowOffset + navItemHeight}px`,
              paddingRight: `0px ${getRTLFlipOptOut()}`,
              paddingBottom: `${shadowOffset}px`,
              paddingLeft: `${shadowOffset}px  ${getRTLFlipOptOut()}`,
              marginTop: `-${shadowOffset + navItemHeight}px`,
              marginRight: `0px  ${getRTLFlipOptOut()}`,
              marginBottom: `-${shadowOffset}px`,
              marginLeft: `-${shadowOffset}px  ${getRTLFlipOptOut()}`,
            },
          },
        },
    ],
    nestedNavLinks: [
      {
        padding: 0,
        listStyle: 'none',
      },
      isNavCollapsed &&
        !isCollapsibleSection && {
          width: `${flyoutNavWidth}px`,
          marginTop: `-${navItemHeight}px`,
          paddingTop: `${navItemHeight}px`,
          backgroundColor: theme.palette.neutralLighter,
          boxShadow:
            '0 1.2px 3.6px rgba(0, 0, 0, 0.09), 0 6.4px 14.4px rgba(0, 0, 0, 0.11)',
        },
      isNavCollapsed && [
        AnimationClassNames.slideRightIn10,
        {
          selectors: {
            '[dir="rtl"] &': [AnimationClassNames.slideLeftIn10],
          },
        },
      ],
    ],
    nestedNavLinkIconContainer: [
      isNavCollapsed &&
        isCollapsibleSection &&
        isExpanded && {
          flex: '0 0 24px',
        },
    ],
  };
};
