import type {
  ITooltipHostProps,
  ITooltipHostStyles,
  ITooltipStyleProps,
} from '@fluentui/react';
import {
  anchorProperties,
  buttonProperties,
  classNamesFunction,
  DirectionalHint,
  getId,
  getNativeProps,
  Icon,
  mergeStyles,
  styled,
  TooltipHost,
} from '@fluentui/react';
import type { IThrowOnUndefinedColorContext } from '@m365-admin/customizations';
import { ThrowOnUndefinedColorContext } from '@m365-admin/customizations';
import type { Context } from 'react';
import { Component } from 'react';
import * as React from 'react';

import { NavTeachingBubble } from '../nav-teaching-bubble/nav-teaching-bubble';
import type {
  INavLinkProps,
  INavLinkState,
  INavLinkStyleProps,
  INavLinkStyles,
} from './nav-link.types';

const getClassNames = classNamesFunction<INavLinkStyleProps, INavLinkStyles>();

/** This fix is related to a flex bug in IE11 that prevents the browser from calculating proper height in flex
 * children. The work around is to wrap the item in another flex container which this does by restyling
 * the TooltipHost we use within this component. As soon as IE11 support is dropped we can remove this
 * https://github.com/philipwalton/flexbugs#flexbug-3
 */
const FlexTooltipHost = styled<ITooltipHostProps, ITooltipStyleProps, ITooltipHostStyles>(
  TooltipHost,
  { root: { display: 'flex' } },
  undefined,
  { scope: 'FlexTooltipHost' },
);

export class NavLinkBase extends Component<INavLinkProps, INavLinkState> {
  private _linkTipId: string = getId('linkTip');

  declare context: React.ContextType<typeof ThrowOnUndefinedColorContext>;
  public static contextType: Context<IThrowOnUndefinedColorContext> =
    ThrowOnUndefinedColorContext;

  public constructor(props: INavLinkProps) {
    super(props);
    this.state = { linkRef: null };
  }

  public render(): JSX.Element {
    const defaultContentRender = (props: INavLinkProps) => {
      const {
        name,
        hasNestedMenu,
        isNested,
        isNavCollapsed,
        primaryIconName,
        isCollapsibleSection,
        onRenderSecondaryContent,
      } = props;

      let iconName = undefined;

      if (hasNestedMenu) {
        iconName = 'ChevronDownMed';
      }

      return (
        <>
          <div className={classNames.iconContainer} role="presentation">
            {primaryIconName && !(isNavCollapsed && isCollapsibleSection) && (
              <Icon iconName={primaryIconName} className={classNames.icon} />
            )}
          </div>
          {name && <div className={classNames.text}>{name}</div>}
          {onRenderSecondaryContent && (
            <div className={classNames.secondaryContent}>
              {onRenderSecondaryContent(props)}
            </div>
          )}
          {(!isNavCollapsed || isCollapsibleSection || isNested) && (
            <Icon
              iconName={iconName}
              className={classNames.secondaryIcon}
              role="presentation"
            />
          )}
        </>
      );
    };

    const {
      name,
      hasNestedMenu,
      isNested,
      isNavCollapsed,
      isExpanded,
      isSelected,
      hasSelectedNestedLink,
      styles,
      primaryIconName,
      href,
      forceAnchor,
      theme,
      showTeachingBubble,
      teachingBubbleProps,
      isParentExpanded,
      preserveIconSpace,
      tooltipProps,
      // tslint:disable-next-line:deprecation
      ariaLabel,
      isCollapsibleSection,
      onRenderNavContent = defaultContentRender,
    } = this.props;
    const classNames = getClassNames(styles, {
      theme: theme!,
      isNavCollapsed: !!isNavCollapsed,
      hasNestedMenu: !!hasNestedMenu,
      isExpanded: !!isExpanded,
      isSelected: !!isSelected,
      hasSelectedNestedLink: !!hasSelectedNestedLink,
      isNested: !!isNested,
      hasIcon: !!primaryIconName,
      preserveIconSpace: !!preserveIconSpace,
      isCollapsibleSection: !!isCollapsibleSection,
      colorThrowContext: this.context,
    });
    const { className, title, disabled, ...nativeProps } = getNativeProps(
      this.props,
      href ? anchorProperties : buttonProperties,
    );

    const navContent = onRenderNavContent(this.props, defaultContentRender);

    forceAnchor &&
      !href &&
      console.warn(
        'If you force true without href prop the link will not work with keyboard',
      );

    const rootStyle = mergeStyles(classNames.root, className);
    const linkBase =
      !href && !forceAnchor ? (
        <button
          aria-label={ariaLabel}
          {...nativeProps}
          onClick={this._onClick}
          className={rootStyle}
          ref={this._setLinkRef}
        >
          {navContent}
        </button>
      ) : (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <a
          aria-label={ariaLabel}
          {...nativeProps}
          onClick={this._onClick}
          className={rootStyle}
          ref={this._setLinkRef}
        >
          {navContent}
        </a>
      );

    // it wouldn't make sense to show a call out on a nested nav item that isn't visible or transient
    const shouldTeachingBubbleShow =
      showTeachingBubble &&
      (!isNested || (!isNavCollapsed && isNested && isParentExpanded));

    return (
      <>
        {disabled || (title === undefined && !isNavCollapsed && !tooltipProps) ? (
          linkBase
        ) : (
          <FlexTooltipHost
            id={this._linkTipId}
            content={(title as string) ?? (isNavCollapsed && !isNested && name)}
            directionalHint={DirectionalHint.rightCenter}
            {...tooltipProps}
          >
            {linkBase}
          </FlexTooltipHost>
        )}
        {shouldTeachingBubbleShow && (
          <NavTeachingBubble target={this.state.linkRef} {...teachingBubbleProps} />
        )}
      </>
    );
  }

  private _onClick = (ev: React.MouseEvent<HTMLElement>): void => {
    if (this.props.onClick) {
      this.props.onClick(ev, this.props.item);
    }
  };

  private _setLinkRef = (
    linkItem: HTMLButtonElement | HTMLAnchorElement | null,
  ): void => {
    // we do this with state because getting a ref is not synchronous
    // without this, a TeachingBubble will not show if showTeachingBubble is true initially
    if (this.state.linkRef !== linkItem) {
      this.setState({ linkRef: linkItem });
    }
  };
}
