import type { IProcessedStyleSet, IRenderFunction } from '@fluentui/react';
import { classNamesFunction } from '@fluentui/react';
import { ThrowOnUndefinedColorContext } from '@m365-admin/customizations';
import { M365Breadcrumb } from '@m365-admin/m365-breadcrumb';
import type { ReactNode } from 'react';
import * as React from 'react';

import { getStepToShow } from '../utilities/index';
import type { IWizardParentState, IWizardStepProps } from '../wizard/index';
import { Wizard } from '../wizard/index';
import type {
  ISetupWizardProps,
  ISetupWizardStyleProps,
  ISetupWizardStyles,
} from './setup-wizard.types';

const { useContext } = React;

const getClassNames = classNamesFunction<ISetupWizardStyleProps, ISetupWizardStyles>();

/** Component for Setup Wizard */
export const SetupWizardBase = (props: ISetupWizardProps, state: IWizardParentState) => {
  const [isNarrow, setIsNarrow] = React.useState(state.isNarrow);

  // Get wizard title section
  const _onRenderTitle: IRenderFunction<ISetupWizardProps> = (setupWizardProps) => {
    if (setupWizardProps?.breadcrumbProps) {
      return (
        <M365Breadcrumb
          {...setupWizardProps.breadcrumbProps}
          styles={classNames.subComponentStyles.breadcrumb}
        />
      );
    }

    if (title) {
      return <div className={classNames.titleSection}>{title}</div>;
    }

    return null;
  };

  const {
    styles,
    theme,
    title,
    wizardProps,
    breadcrumbProps,
    onRenderTitle = _onRenderTitle,
  } = props;

  const context = useContext(ThrowOnUndefinedColorContext);

  const classNames: IProcessedStyleSet<ISetupWizardStyles> = getClassNames(styles, {
    theme: theme!,
    isNarrow: isNarrow,
    isLoading: !!wizardProps.isLoading,
    colorThrowContext: context,
  });

  const stepToShow = getStepToShow(wizardProps);

  // Get wizard action bar
  const onRenderActionBar = (step: IWizardStepProps): ReactNode => {
    return <div className={classNames.actionBarSection}>{step.footerElement}</div>;
  };

  const onIsNarrowChanged = (newNarrowValue: boolean): void => {
    setIsNarrow(newNarrowValue);

    if (wizardProps?.onIsNarrowChanged) {
      wizardProps.onIsNarrowChanged(newNarrowValue);
    }
  };

  if (title && breadcrumbProps) {
    console.warn(
      'It looks like you have passed in both a title and breadcrumbProps. Title will be overridden by breadcrumbProps. Please choose one or the other.',
    );
  }

  if (breadcrumbProps?.styles) {
    console.warn(
      'It looks like you passed in breadcrumb.styles. These will be overwritten. Please use SetupWizardProps.styles.subComponentStyles.breadcrumb instead.',
    );
  }

  return (
    <div className={classNames.wizardContainer}>
      {onRenderTitle(props, _onRenderTitle)}
      <Wizard
        {...props.wizardProps}
        stepToShow={stepToShow}
        onIsNarrowChanged={onIsNarrowChanged}
        styles={classNames.subComponentStyles.wizard}
      />
      {onRenderActionBar(stepToShow)}
    </div>
  );
};
