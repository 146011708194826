import type { ComponentsStyles } from '@fluentui/react';
import { Panel, ThemeProvider } from '@fluentui/react';
import type { IM365Theme } from '@m365-admin/customizations';
import {
  getPanelTheme,
  ThrowOnUndefinedColorContext,
  useM365Theme,
} from '@m365-admin/customizations';
import { type FunctionComponent, useMemo } from 'react';
import * as React from 'react';

import { getM365PanelStyleOverrides } from './m365-panel.style';
import type { IM365PanelProps } from './m365-panel.types';

export const M365Panel: FunctionComponent<IM365PanelProps> = (props: IM365PanelProps) => {
  const theme = useM365Theme();
  const { usePanelizedTheme = true, usePanelizedStyles = true } = props;
  const panelizedTheme = usePanelizedTheme ? getPanelTheme(theme) : theme;
  const throwContext = React.useContext(ThrowOnUndefinedColorContext);
  const panelizedStyles = useMemo<ComponentsStyles>(
    () => (usePanelizedStyles ? getM365PanelStyleOverrides(theme, throwContext) : {}),
    [theme, throwContext, usePanelizedStyles],
  );

  const panelTheme = useMemo<IM365Theme>(
    () => ({
      ...panelizedTheme,
      components: { ...panelizedTheme.components, ...panelizedStyles },
    }),
    [panelizedStyles, panelizedTheme],
  );

  return (
    <>
      <ThemeProvider theme={panelTheme}>
        <Panel {...props} />
      </ThemeProvider>
    </>
  );
};
