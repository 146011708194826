import { detailPanelGutterWidth } from '@m365-admin/customizations';

import {
  getWizardTitleStyles,
  subwayNavPadding,
  wizardBreadcrumbStyles,
} from '../wizard/index';
import type { IPanelWizardStyleProps, IPanelWizardStyles } from './panel-wizard.types';

// This accounts for the padding built into the panel by fabric
export const panelPadding = 16;
export const narrowTitleElementVerticalPadding = 12;

const componentName = 'PanelWizard';

export const getPanelWizardStyles = (
  props: IPanelWizardStyleProps,
): IPanelWizardStyles => {
  const { isWindowNarrow, isWizardNarrow, isLoading, theme, colorThrowContext } = props;

  return {
    titleElementContainer: [
      getWizardTitleStyles({
        theme,
        componentName,
        isNarrow: isWizardNarrow,
        colorThrowContext,
      }),
      isWindowNarrow && {
        paddingTop: narrowTitleElementVerticalPadding,
        paddingBottom: narrowTitleElementVerticalPadding,
        paddingLeft: detailPanelGutterWidth,
        borderBottom: `1px solid ${theme.semanticColors.bodyDivider}`,
      },
    ],
    subComponentStyles: {
      wizard: {
        subwayNavSection: {
          borderRight: `1px solid ${theme.semanticColors.bodyDivider}`,
          paddingLeft: isWindowNarrow ? detailPanelGutterWidth : subwayNavPadding,
        },
        wizardContentNavContainer: [
          isWindowNarrow && {
            height: isLoading ? '100%' : 'auto',
            marginBottom: 0,
          },
        ],
        wizardProgress: {}, // deprecated, no effect anymore.
      },
      panel: {
        scrollableContent: {
          paddingLeft: 0,
          paddingRight: 0,
        },
        content: {
          paddingLeft: 0,
          paddingRight: 0,
          paddingBottom: isWizardNarrow && !isWindowNarrow ? subwayNavPadding : 0,
        },
        navigation: {
          padding: '0px',
          justifyContent: 'space-between',
          flex: '1 1 0%',
        },
        footer: {
          borderTop: `1px solid ${theme.semanticColors.bodyDivider}`,
        },
        // Account for the padding baked into the panel and any potential scroll bar
        footerInner: [
          { padding: -panelPadding },
          isWizardNarrow && { paddingRight: panelPadding },
        ],
        commands: {
          display: 'flex',
          flexDirection: 'row',
          borderBottom: `1px solid ${theme.semanticColors.bodyDivider}`,
          marginTop: 0,
        },
      },
      breadcrumb: {
        ...wizardBreadcrumbStyles,
        root: {
          margin: '0px',
        },
      },
    },
  };
};
