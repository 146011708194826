import type { IGetFocusStylesOptions, IStyle } from '@fluentui/react';
import { FontWeights, getFocusStyle } from '@fluentui/react';
import type {
  IM365Theme,
  IThrowOnUndefinedColorContext,
} from '@m365-admin/customizations';
import { addAlphaChannelToHex, throwOnUndefinedColor } from '@m365-admin/customizations';

import type {
  IconColorMap,
  IconRingColorMap,
  ISubwayNavNodeStyleProps,
  ISubwayNavNodeStyles,
  SubwayNavStateMap,
} from './subway-node.types';
import { IconNames, SubwayNavNodeState } from './subway-node.types';

export const itemSpacing = 27;
export const largeSubwayNavIconSize = 16;
export const smallSubwayNavIconSize = 8;

export const getIconMap = (isSubStep: boolean): SubwayNavStateMap => {
  return isSubStep
    ? {
        Completed: IconNames.StatusCircleCheckMark,
        CurrentWithSubSteps: undefined,
        Current: IconNames.FullCircleMask,
        Error: IconNames.StatusError,
        NotStarted: undefined,
        Skipped: undefined,
        Unsaved: IconNames.FullCircleMask,
        ViewedNotCompleted: IconNames.FullCircleMask,
        WizardComplete: IconNames.StatusCircleCheckMark,
      }
    : {
        Completed: IconNames.CompletedSolid,
        CurrentWithSubSteps: IconNames.FullCircleMask,
        Current: IconNames.FullCircleMask,
        Error: IconNames.StatusErrorFull,
        NotStarted: undefined,
        Skipped: undefined,
        Unsaved: IconNames.FullCircleMask,
        ViewedNotCompleted: IconNames.FullCircleMask,
        WizardComplete: IconNames.CompletedSolid,
      };
};

export const getIconColorMap = (
  isSubStep: boolean,
  theme: IM365Theme,
  colorThrowContext?: IThrowOnUndefinedColorContext,
): IconColorMap => {
  return isSubStep
    ? {
        Completed: throwOnUndefinedColor(
          theme.semanticColors.stepCompleted,
          'stepCompleted',
          'SubwayNode',
          { context: colorThrowContext, theme },
        ),
        CurrentWithSubSteps: throwOnUndefinedColor(
          theme.semanticColors.stepCurrent,
          'stepCurrent',
          'SubwayNode',
          { context: colorThrowContext, theme },
        ),
        Current: throwOnUndefinedColor(
          theme.semanticColors.stepCurrent,
          'stepCurrent',
          'SubwayNode',
          { context: colorThrowContext, theme },
        ),
        Error: throwOnUndefinedColor(
          theme.semanticColors.stepError,
          'stepError',
          'SubwayNode',
          { context: colorThrowContext, theme },
        ),
        NotStarted: 'transparent',
        Skipped: 'transparent',
        Unsaved: theme.palette.themeLighter,
        ViewedNotCompleted: theme.palette.accent,
        WizardComplete: throwOnUndefinedColor(
          theme.semanticColors.allStepsComplete,
          'allStepsComplete',
          'SubwayNode',
          { context: colorThrowContext, theme },
        ),
      }
    : {
        Completed: throwOnUndefinedColor(
          theme.semanticColors.stepCompleted,
          'stepCompleted',
          'SubwayNode',
          { context: colorThrowContext, theme },
        ),
        CurrentWithSubSteps: throwOnUndefinedColor(
          theme.semanticColors.stepCompleted,
          'stepCompleted',
          'SubwayNode',
          { context: colorThrowContext, theme },
        ),
        Current: throwOnUndefinedColor(
          theme.semanticColors.stepCurrent,
          'stepCurrent',
          'SubwayNode',
          { context: colorThrowContext, theme },
        ),
        Error: throwOnUndefinedColor(
          theme.semanticColors.stepError,
          'stepError',
          'SubwayNode',
          { context: colorThrowContext, theme },
        ),
        NotStarted: 'transparent',
        Skipped: 'transparent',
        Unsaved: theme.palette.themeLighter,
        ViewedNotCompleted: theme.palette.accent,
        WizardComplete: throwOnUndefinedColor(
          theme.semanticColors.allStepsComplete,
          'allStepsComplete',
          'SubwayNode',
          { context: colorThrowContext, theme },
        ),
      };
};

export const getIconRingColorMap = (
  theme: IM365Theme,
  colorThrowContext?: IThrowOnUndefinedColorContext,
): IconRingColorMap => {
  return {
    Completed: 'transparent',
    CurrentWithSubSteps: 'transparent',
    Current: 'transparent',
    Error: 'transparent',
    NotStarted: throwOnUndefinedColor(
      theme.semanticColors.stepNotStarted,
      'stepNotStarted',
      'SubwayNode',
      { context: colorThrowContext, theme },
    ),
    Skipped: theme.palette.accent,
    Unsaved: theme.palette.accent,
    ViewedNotCompleted: 'transparent',
    WizardComplete: 'transparent',
  };
};

export const getSubwayNodeStyles = (
  props: ISubwayNavNodeStyleProps,
): ISubwayNavNodeStyles => {
  const {
    disabled,
    isVisuallyDisabled,
    isSubStep,
    iconRecord,
    state,
    theme,
    colorThrowContext,
  } = props;

  const options: IGetFocusStylesOptions = {
    inset: undefined,
    position: undefined,
    highContrastStyle: {
      outlineColor: theme.semanticColors.focusBorder,
    },
    borderColor: 'transparent',
    outlineColor: undefined,
  };
  const useSelectedStyle: boolean =
    state === SubwayNavNodeState.Current ||
    state === SubwayNavNodeState.CurrentWithSubSteps;

  const commonLabelStyles: IStyle = [
    {
      transition: 'all .1s',
    },
  ];

  const isActuallyVisuallyDisabled = disabled && isVisuallyDisabled;
  const visualDisabledBehavior = (hexColor: string) => {
    return isActuallyVisuallyDisabled ? addAlphaChannelToHex(hexColor, 50) : hexColor;
  };

  // Apply the small size to all the substeps EXCEPT error and completed since they have special icons.
  const iconSize: number =
    isSubStep &&
    state !== SubwayNavNodeState.Error &&
    state !== SubwayNavNodeState.Completed &&
    state !== SubwayNavNodeState.WizardComplete
      ? smallSubwayNavIconSize
      : largeSubwayNavIconSize;

  return {
    root: [
      getFocusStyle(theme, options),
      {
        display: 'flex',
        alignItems: 'flex-start',
        cursor: disabled ? 'default' : 'pointer',
        background: 'none',
        width: '100%',
        border: 'none',
        textAlign: 'left',
        padding: 0,
      },
    ],
    iconContainer: [
      {
        flex: '0 0 16px',
        height: '16px',
        display: 'block',
        padding: isSubStep ? '0px' : '4px 0px',
        backgroundColor: theme.semanticColors.bodyBackground,
        boxSizing: 'content-box',
        overflow: 'visible',
      },
    ],
    icon: [
      iconRecord?.subset.className,
      {
        fill: visualDisabledBehavior(
          getIconColorMap(isSubStep, theme, colorThrowContext)[state],
        ),
        fontSize: iconSize,
      },
    ],
    iconBackPlate: [
      {
        fill: visualDisabledBehavior(theme.semanticColors.accentButtonText),
      },
    ],
    iconRing: [
      {
        stroke: getIconRingColorMap(theme, colorThrowContext)[state],
        fill: 'none',
        opacity: isActuallyVisuallyDisabled ? 0.5 : undefined,
        strokeWidth: 2,
        fontSize: iconSize,
      },
    ],
    spacer: [{ flex: '0 0 12px' }],
    label: [
      commonLabelStyles,
      {
        opacity: useSelectedStyle ? 0 : 1,
      },
    ],
    labelSelected: [
      commonLabelStyles,
      {
        opacity: useSelectedStyle ? 1 : 0,
        fontWeight: FontWeights.bold,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
      },
    ],
    labelWrapper: [
      isSubStep ? theme.fonts.small : theme.fonts.medium,
      {
        flex: '1 1 auto',
        color: visualDisabledBehavior(theme.semanticColors.bodyText),
        backgroundColor: theme.semanticColors.bodyBackground,
        position: 'relative',
        marginTop: isSubStep ? '-0.05em' : '0.15em',
      },
    ],
  };
};
